import React from "react";
import ReactDOM from "react-dom";
import "styles/index.scss";
import { Root } from "app/Root";
import SettingsContextProvider from "app/context";

ReactDOM.render(
  <>
    <SettingsContextProvider>
      <Root />
    </SettingsContextProvider>
  </>,
  document.getElementById("root")
);
