import "./style.scss";
export const About = () => (
  <div className="aboutUs" id="aboutUs">
    <h2>
      Our <span>story</span>
    </h2>
    <div className="smallerContent">
      <div className="upper">
        <p>
          EcoWay is a project based on BEP20 protocol functioning within a
          decentralized data register (blockchain).
          <br />
          <br />
          The objective of the Project is increasing the operating scale of
          EcoWay mine and making its resources in the form of plots available
          for all the holders of ECY tokens issued by EcoWay by means of a
          dedicated e-commerce platform.
        </p>
        <div className="pic" />
      </div>
      <div className="lower">
        <p>
          As part of the continuously developed IT infrastructure, EcoWay
          platform renders a service of making the space of hard disks available
          to the benefit of CHIA protocol based on the decentralized data
          register (blockchain), with the simultaneous distribution of proceeds
          generated on this ground according to the rules detailed in{" "}
          <a target="_blank" href="/assets/Whitepaper.pdf">
            this WHITEPAPER.
          </a>
          <br />
          <br />
          In 2021, EcoWay team built a fully operating Chia cryptocurrency mine
          in the European Union, which includes 170 plotters and 2500 HDD disks
          dedicated to the storage of plots with the approximate capacity of
          10,1 PiB.
        </p>
      </div>
    </div>
    <div className="biggerContent">
      <div className="bigFlex">
        <div className="aboutWrapper">
          <p>
            EcoWay is a project based on BEP20 protocol functioning within a
            decentralized data register (blockchain).
            <br />
            <br />
            The objective of the Project is increasing the operating scale of
            EcoWay mine and making its resources in the form of plots available
            for all the holders of ECY tokens issued by EcoWay by means of a
            dedicated e-commerce platform.
            <br />
            <br />
            As part of the continuously developed IT infrastructure, EcoWay
            platform renders a service of making the space of hard disks
            available to the benefit of CHIA protocol based on the decentralized
            data register (blockchain), with the simultaneous distribution of
            proceeds generated on this ground according to the rules detailed in{" "}
            <a target="_blank" href="/assets/Whitepaper.pdf">
              this WHITEPAPER
            </a>
            .
            <br />
            <br />
            In 2021, EcoWay team built a fully operating Chia cryptocurrency
            mine in the European Union, which includes 170 plotters and 2500 HDD
            disks dedicated to the storage of plots with the approximate
            capacity of 10,1 PiB.
          </p>
        </div>
        <div className="bigPic" />
      </div>
    </div>
  </div>
);
