import useMountTransition from "app/Hooks/useMountTransition";
import arrowDown from "img/icons/misc/arrow2.svg";
import { useState } from "react";
import "./style.scss";

export const AllYouNeed = ({ position }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(!isOpen);
  const hasTransitionedIn = useMountTransition(isOpen, 250);

  return (
    <div
      className={`menuBtn ${
        position === "top" ? "topBtn" : position === "bottom" ? "bottomBtn" : ""
      }`}
    >
      <button
        className={`allYouNeed pillBtn ${
          position === "top" ? "colorful" : isOpen ? "" : "olive"
        }`}
        onClick={handleClick}
      >
        <span>Whitepaper</span>
        <img
          src={arrowDown}
          alt="documents"
          style={
            !isOpen
              ? { transition: "transform 0.5s", transform: "rotate(0deg)" }
              : { transition: "transform 0.5s", transform: "rotate(-180deg)" }
          }
        />
      </button>
      {(hasTransitionedIn || isOpen) && (
        <div
          className={`moreMenu ${hasTransitionedIn && "in"} ${
            isOpen && "visible"
          } ${position === "top" ? "colorful" : "olive"}`}
        >
          <ul>
            <li>
              <a target="_blank" href="/assets/Whitepaper.pdf">
                Download Whitepaper
              </a>
            </li>
            <li>
              <a target="_blank" href="/assets/Litepaper.pdf">
                Download Litepaper
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
