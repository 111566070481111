import { Progress } from "app/pages/Home/components/EmissionCapsule/components/Progress";
import "./style.scss";
import arrow from "img/icons/misc/arrow-white.svg";
import pic0 from "img/icons/stages/0.png";
import pic1 from "img/icons/stages/1.png";
import pic2 from "img/icons/stages/2.png";
import pic3 from "img/icons/stages/3.png";
import { CountdownTimer } from "app/components/Countdown";
import data from "data/emissionData.json";

const items = [
  { title: "Private sale", subtitle: null, pic: pic0 },
  { title: "Pre-sale", subtitle: null, pic: pic1 },
  {
    title: "Public sale",
    subtitle: "1st emmision",
    pic: pic2,
  },
  {
    title: "Public sale",
    subtitle: "Next emmision",
    pic: pic3,
  },
];

const timerDate = new Date(data.countdownDate).valueOf();

export const Emission = () => (
  <div className="emissionProgress" id="emission">
    <h2>
      Emission <br />
      <span>Progress</span>
    </h2>
    <div className="emissionProgressBigWrapper">
      <div className="emissionProgressContentWrapper">
        <div className="emissionProgressContent">
          <div className="emissionProgressPhases">
            {items.map(({ title, subtitle, pic, isActive }, index) => (
              <div
                key={index}
                className={`emissionProgressPhase element__${index}`}
                style={
                  index + 1 === data.activeEmissionPhase
                    ? { margin: "0 10px" }
                    : {}
                }
              >
                <div className="emissionProgressPhasePhoto">
                  <img
                    style={
                      index + 1 === data.activeEmissionPhase
                        ? { height: "76px", width: "76px", opacity: "1" }
                        : {}
                    }
                    src={pic}
                    alt=""
                  />
                </div>
                <div className="emissionProgressPhaseStepWrapper">
                  <div
                    className="emissionProgressPhaseStep"
                    style={
                      index + 1 === data.activeEmissionPhase
                        ? { background: "#000", width: "41px", height: "41px" }
                        : {}
                    }
                  >
                    <b
                      style={
                        index + 1 === data.activeEmissionPhase
                          ? { fontSize: "16px", lineHeight: "31px" }
                          : {}
                      }
                    >
                      {index + 1}
                    </b>
                  </div>
                </div>
                <div className="emissionProgressPhaseTxtWrapper">
                  <span
                    style={
                      index + 1 === data.activeEmissionPhase
                        ? { color: "#000", fontWeight: "bold" }
                        : {}
                    }
                    className="emissionProgressPhaseTitle"
                  >
                    {title}
                  </span>
                  {subtitle && (
                    <span
                      style={
                        index + 1 === data.activeEmissionPhase
                          ? { color: "#000" }
                          : {}
                      }
                      className="emissionProgressPhaseSubTitle"
                    >
                      {subtitle}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="emissionProgressStatusWrapper">
            <Progress />
            <a
              className="icoWrapper"
              href="https://ico.ecoway.org/login"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <button className="pillBtn whiteGreen">
                <span>Buy Token</span>
                <img src={arrow} alt="" />
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="emissionProgressBigPhoto" />
    </div>
    <div className="emissionProgressSmallWrapper">
      <CountdownTimer targetDate={timerDate} />
      <div className="emissionProgressSmallPhotoWrapper">
        <div className="emissionProgressSmallPhoto" />
      </div>
    </div>
  </div>
);
