import { GoUp } from "app/components/GoUp";
import "./style.scss";
import reload from "img/icons/misc/color-reload.svg";
import arrow from "img/icons/misc/arrow3.svg";
import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip
} from "recharts";

export const Calc = () => {
  const [USD, setUSD] = useState("")
  const [ECY, setECY] = useState("")
  const [PiBNetworkSpace, setPiBNetworkSpace] = useState(0)
  const [chiaUSD, setChiaUSD] = useState(0)
  const [stats, setStats] = useState("hourly")
  const [chart, setChart] = useState("twoK")
  const [dropdown, setDropdown] = useState(false)
  const [chiaPerDay, setChiaPerDay] = useState(0)
  const [XCH, setXCH] = useState({
    hourly: 0,
    daily: 0,
    monthly: 0
  })
  const [earnUSD, setEarnUSD] = useState({
    hourly: 0,
    daily: 0,
    monthly: 0
  })

  const dropdownMap = {
    current: "Current price",
    twoK: "Chia 2k price",
    fiveK: "Chia 5k price"
  }

  const tokenLimit = 50000

  async function getFarmStats() {
    try {
      let response = await fetch("https://api.ecoway.org/farm_send_stats.php?select=1", {
              method: 'GET'
          }
      );
  
      let res = await response.json();
   
      if (res.plots) {
        setPiBNetworkSpace(res.total_net)
      } else {
        console.log(res)
      }
      return;
    } catch (error) {
      console.log(error)
    }
  }

  async function getChiaUSD() {
    try {
      let response = await fetch("https://api.coingecko.com/api/v3/coins/chia?vs_currency=usd", {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
          }
      );
  
      let res = await response.json();
   
      if (res.id) {
        setChiaUSD(res.market_data?.current_price?.usd)
      } else {
        console.log(res)
      }
      return;
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(PiBNetworkSpace === 0) getFarmStats()
    if(chiaUSD === 0) getChiaUSD()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const n = 101.4*ECY/1048*1000/1000000
    const cpd = n / (PiBNetworkSpace*1024)*4608*2
    setChiaPerDay(cpd)
    setXCH({
      hourly: cpd/24,
      daily: cpd,
      monthly: cpd*30
    })
    setEarnUSD({
      hourly: (cpd/24)*chiaUSD,
      daily: cpd*chiaUSD,
      monthly: (cpd*30)*chiaUSD
    })
    //eslint-disable-next-line
  }, [ECY])

  const toFixed = (num, fixed) => {
    if (num) {
      var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
      return num.toString().match(re)[0];
    } else {
      return 0
    }
  }

  const chiaPerYear5kPrice = (((chiaPerDay*30))*5000)*12;
  const percentReturn5y = (100*(chiaPerYear5kPrice*5))/USD;

  const chartInitialValues = {
    current: (((chiaPerDay*30))*chiaUSD)*12,
    twoK: (((chiaPerDay*30))*2000)*12,
    fiveK: (((chiaPerDay*30))*5000)*12
  }

  const chartData = [
    {
      name: "2022",
      val: parseInt((chartInitialValues[chart]).toFixed(0))
    },
    {
      name: "2023",
      val: parseInt((chartInitialValues[chart]*2).toFixed(0))
    },
    {
      name: "2024",
      val: parseInt((chartInitialValues[chart]*3).toFixed(0))
    },
    {
      name: "2025",
      val: parseInt((chartInitialValues[chart]*4).toFixed(0))
    },
    {
      name: "2026",
      val: parseInt((chartInitialValues[chart]*5).toFixed(0))
    }
  ]

  const DataFormater = (number) => {
    if(number > 1000000000){
      return (number/1000000000).toString() + 'B';
    }else if(number > 1000000){
      return (number/1000000).toString() + 'M';
    }else if(number > 1000){
      return (number/1000).toString() + 'K';
    }else{
      return number.toString();
    }
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Value : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };

  return (
    <div className="calc" id="roiCalc">
      <div className="calcWrapper">
        <h2>
          <span>Return on Investment</span>
          <br />
          <span>(ROI)</span> Calculator
        </h2>
        <div className="calcContainer">
          <div className="calcInputs">
            <div className="calcInput">
              <span>Investment USD</span>
              <input className="calc-usd" type="number" placeholder="2460" value={USD} onChange={ (e) => {
                  let tk = parseInt(toFixed(parseFloat(parseInt(e.target.value)/8.2),0))
                  if(tk > tokenLimit) {
                    tk = tokenLimit
                    setUSD(toFixed(parseFloat(parseInt(tk)*8.2),2))
                  } else {
                    setUSD(e.target.value)
                  }
                  setECY(tk)
                } }/>
            </div>
            <button className="calcReload">
              <img src={reload} alt="" />
            </button>
            <div className="calcInput">
              <span>ECY Tokens</span>
              <input className="calc-ecy" type="number" placeholder="300"  value={ECY} onChange={ (e) => {
                  let tk = e.target.value
                  if(tk > tokenLimit) {
                    tk = tokenLimit
                  }
                  setECY(tk)
                  setUSD(toFixed(parseFloat(parseInt(tk)*8.2),2))
                } }/>
            </div>
          </div>
          <div className="calcCharts">
            <div className="calcChart chartsTile">
              <button className={ dropdown ? "chartTileDropdown open" : "chartTileDropdown"} onClick={() => { setDropdown(!dropdown) }}>
                <b>{ dropdownMap[chart] }</b>
                <img src={arrow} alt="" />
                { dropdown && <ul className="dropdown">
                  <li onClick={() => { setDropdown(false); setChart("current") }}>CurrentPrice</li>
                  <li onClick={() => { setDropdown(false); setChart("twoK") }}>Chia 2k price</li>
                  <li onClick={() => { setDropdown(false); setChart("fiveK") }}>Chia 5k price</li>
                </ul>}
              </button>
              {/* <img src={placeholderChart} alt="" /> */}
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                key={chartInitialValues[chart]}
                  data={chartData}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0
                  }}
                >
                  <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#439C47" stopOpacity={1}/>
                          <stop offset="95%" stopColor="#43BACE" stopOpacity={1}/>
                      </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} />
                  <XAxis dataKey="name" tick={true} tickSize={0} tickMargin={10} axisLine={false}/>
                  <YAxis tickSize={0} axisLine={false} tickFormatter={DataFormater}/>
                  <Tooltip content={<CustomTooltip />} cursor={{fill: "rgba(0, 0, 0, 0.1)"}}/>
                  <Bar dataKey="val" fill="url(#colorUv)" radius={[15, 15, 15, 15]} barSize={30}/>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="calcChart inputsTile">
              <div className="inputsTileHeader">
                <div>
                  <span>Hourly</span>
                  <input type="radio" checked={stats === "hourly"} name="stats" value="hourly" onChange={ () => { setStats("hourly") } }/>
                </div>
                <div>
                  <span>Daily</span>
                  <input type="radio" checked={stats === "daily"} name="stats" value="daily" onChange={ () => { setStats("daily") } }/>
                </div>
                <div>
                  <span>Monthly</span>
                  <input type="radio" checked={stats === "monthly"} name="stats" value="monthly" onChange={ () => { setStats("monthly") } }/>
                </div>
              </div>
              <div className="inputsTileContent">
              { stats === "hourly" && <>
                  <div className="chartIntervalValue">
                    <span>Hourly XCH</span>
                    <b>{ (XCH.hourly || 0).toFixed(4) }</b>
                  </div>
                  <div className="chartIntervalValue">
                    <span>Hourly USD</span>
                    <b>{ (earnUSD.hourly || 0).toFixed(4) }</b>
                  </div>
                </> }
                { stats === "daily" && <>
                  <div className="chartIntervalValue">
                    <span>Daily XCH</span>
                    <b>{ (XCH.daily || 0).toFixed(4) }</b>
                  </div>
                  <div className="chartIntervalValue">
                    <span>Daily USD</span>
                    <b>{ (earnUSD.daily || 0).toFixed(4) }</b>
                  </div>
                </> }
                { stats === "monthly" && <>
                  <div className="chartIntervalValue">
                    <span>Monthly XCH</span>
                    <b>{ (XCH.monthly || 0).toFixed(4) }</b>
                  </div>
                  <div className="chartIntervalValue">
                    <span>Monthly USD</span>
                    <b>{ (earnUSD.monthly || 0).toFixed(4) }</b>
                  </div>
                </> }
                <div className="chartIntervalSmallTxt">
                  <b>5 Years ROI at 5000$ per chia:</b>
                  <span>{((chiaPerYear5kPrice*5) || 0).toFixed(2)} USD ({(percentReturn5y || 0).toFixed(2)} %)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GoUp />
      </div>
    </div>
  );
}
