import "./style.scss";
import logo from "img/logos/ecoway/color.svg";
import links from "data/socialLinks.json";
import { SOCIAL_BLACK_ICONS as icons } from "img/icons/social/black";
import { Link } from "react-router-dom";

export const Footer = () => (
  <>
    <div className="footer">
      <div className="footerContent">
        <Link to="/">
          <img className="logo" src={logo} alt="logo" />
        </Link>
        <div className="footerWrapper">
          <div className="footerColumns">
            <ul className="footerColumn">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/tokenomics">Tokenomics</Link>
              </li>
            </ul>
            <ul className="footerColumn">
              <li>
                <a target="_blank" href="/assets/Whitepaper.pdf">
                  Whitepaper
                </a>
              </li>
            </ul>
          </div>
          <div className="contact">
            <b>Contact us</b>
            <span>
              Do you have any questions? Send us message and we will contact you
              back.
            </span>
            <br />
            <a href="mailto:contact@ecoway.org">contact@ecoway.org</a>
          </div>
          <div className="followUs">
            <b>Follow us:</b>
            <ul className="socialLinks">
              {links.map(({ url, name }) => (
                <li key={name}>
                  <a href={url} rel="nofollow noopener noreferrer">
                    <img src={icons[name]} alt={name} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="disclaimer">
      <span>
        EcoWay © 2023
        <br />
        EcoWay is operated full and on its own. EcoWay is not associated to Chia
        Network, Inc. and every licensed trademark <br className="br" />
        displayed on this website belongs to their respective owners.
      </span>
    </div>
  </>
);
