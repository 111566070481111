import arrow from "img/icons/misc/arrow.svg";
import "./style.scss";

export const GoUp = () => (
  <div className="goUp">
    <div>
      <span>Back to top</span>
      <button onClick={() => window.scrollTo(0, 0)}>
        <img src={arrow} alt="go to top" />
      </button>
    </div>
  </div>
);
