import React, { useState, useEffect } from 'react';
import { Capsule } from "app/components/Capsule";
import { formatNumber } from "app/utils/bigNumbers";
import data from "data/emissionData.json";
import { BIG_EMISSION_ICONS as icons } from "img/icons/misc/big";
import { Item } from "../components/Item";
import { Progress } from "../components/Progress";
import "../style.scss";

const { tokenPrice, softCap, hardCap } = data;

export const DesktopEmissionCapsule = () => {
  const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date().toLocaleDateString());
    }, 1000); // Aktualizacja daty co sekundę

    return () => clearInterval(intervalId); // Wyczyść interwał przy odmontowywaniu
  }, []);

  return (
    <Capsule>
      <div className="desktopCapsuleContent">
        <div className="info">
          <Item
            pic={icons.sun}
            title={"ECY"}
            subtitle="token name"
          />
          <Item
            pic={icons.clock}
            title={currentDate}
            subtitle="last update"
          />
          <Item
            pic={icons.star}
            title="205 578" // Stały tekst zamiast obliczanej wartości
            subtitle="circulating supply"
          />
        </div>
        <Progress />
      </div>
    </Capsule>
  );
};
