import { About } from "./components/About";
import { Partners } from "./components/About/Partners";
import { Footer } from "app/components/Footer";
import { Top } from "./components/Top";
import { Video } from "./components/Video";
import InTheMedia from "./components/InTheMedia";

export const Home = () => (
  <div className="page">
    <Top />
    <Video />
    <InTheMedia/>
    <About />
    <Partners />
    <Footer />
  </div>
);
