import "./style.scss";
import arrow from "img/icons/misc/arrow-white.svg";
import { TopBar } from "app/components/TopBar";
import { scrollTo } from "app/utils/scrollTo";

export const Top = () => {
  return (
    <>
      <div className="topAbout">
        <TopBar />
        <a
          className="icoWrapper"
          href="https://ico.ecoway.org/login"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
        </a>
        <div className="wrapper">
          <div className="knowUs">
            <h1>
              Get to know
              <br />
              us <b>better</b>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};
