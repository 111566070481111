import { ScrollToTop } from "app/components/ScrollToTop";
import { Home } from "app/pages/Home";
import { AboutUs } from "app/pages/AboutUs";
import { Tokenomics } from "app/pages/Tokenomics";
import { StakeAndDistribution } from "app/pages/StakeAndDistribution";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MobileMenu } from "app/components/MobileMenu";
import { useSettingsContext } from "app/context";
import {Helmet} from "react-helmet";

export const Root = () => {
  const { isMenuOpen, setIsMenuOpen } = useSettingsContext();
  return (
    <div className="app">
      <Helmet>
        <title>Buy Token - EcoWay</title>
        <meta name="description" content="EcoWay is a high-performance blockchain. Buy tokens to access the chia farming without building your own infrastructure. It’s the best place to generate profit." />
      </Helmet>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/about" element={<AboutUs />}></Route>
            <Route exact path="/tokenomics" element={<Tokenomics />}></Route>
            <Route
              exact
              path="/stake_and_distribution"
              element={<StakeAndDistribution />}
            />
          </Routes>
        </ScrollToTop>
        {isMenuOpen && <MobileMenu setOpen={setIsMenuOpen} />}
      </BrowserRouter>
    </div>
  );
};
