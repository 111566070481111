import { useCountdown } from "app/Hooks/useCountdown";
import data from "data/emissionData.json";

export const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const { countdownText } = data;

  if (days + hours + minutes + seconds <= 0) {
    return (
      <div className="emissionProgressCountdown">
        <b className="emissionProgressCountdownTitle">{countdownText}</b>
        <div className="emissionProgressCountdownTiles">
          <div className="emissionProgressCountdownTile">
            <span>Days</span>
            <div>
              <b>0</b>
            </div>
          </div>
          <span className="timeSeparator">:</span>
          <div className="emissionProgressCountdownTile">
            <span>Hours</span>
            <div>
              <b>0</b>
            </div>
          </div>
          <span className="timeSeparator">:</span>
          <div className="emissionProgressCountdownTile">
            <span>Minutes</span>
            <div>
              <b>0</b>
            </div>
          </div>
          <span className="timeSeparator">:</span>
          <div className="emissionProgressCountdownTile">
            <span>Seconds</span>
            <div>
              <b>0</b>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="emissionProgressCountdown">
        <b className="emissionProgressCountdownTitle">{countdownText}</b>
        <div className="emissionProgressCountdownTiles">
          <div className="emissionProgressCountdownTile">
            <span>Days</span>
            <div>
              <b>{days}</b>
            </div>
          </div>
          <span className="timeSeparator">:</span>
          <div className="emissionProgressCountdownTile">
            <span>Hours</span>
            <div>
              <b>{hours}</b>
            </div>
          </div>
          <span className="timeSeparator">:</span>
          <div className="emissionProgressCountdownTile">
            <span>Minutes</span>
            <div>
              <b>{minutes}</b>
            </div>
          </div>
          <span className="timeSeparator">:</span>
          <div className="emissionProgressCountdownTile">
            <span>Seconds</span>
            <div>
              <b>{seconds}</b>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
