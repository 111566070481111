import grafkon from "./grafkon.png";
import greendeal from "./greendeal.png";
import greenevo from "./greenevo.png";
import gtmine from "./gtmine.png";
import innovative from "./innovative.png";
import nextrope from "./nextrope.png";
import ng from "./ng.png";
import today from "./today.png";
import khg from "./khg.png";

export const PARTNERS = [
  ng,
  innovative,
  gtmine,
  greenevo,
  grafkon,
  greendeal,
  nextrope,
  today,
  khg,
];
