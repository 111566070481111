import { Capsule } from "app/components/Capsule";
import { PARTNERS } from "img/logos/partners";
import { GoUp } from "app/components/GoUp";
import "./style.scss";

export const Partners = () => (
  <div className="partners">
    <h3 className="title">
      Our <b>partners</b>
    </h3>
    <Capsule>
      <div className="logos">
        {PARTNERS.map((it, index) => (
          <img key={index} src={it} alt="" />
        ))}
      </div>
    </Capsule>
    <GoUp />
  </div>
);
