import "./style.scss";
import arrow from "img/icons/misc/arrow.svg";
import { Link } from "react-router-dom";
import data from "data/emissionData.json";

export const About = () => (
  <>
    <div className="whatIs">
      <div>
        <h2>
          What is
          <br />
          <span>EcoWay?</span>
        </h2>
        <p>
          <b>EcoWay</b> is one of the largest ecological "farms" in Europe for
          mining energy-saving cryptocurrencies of the future.
          <br />
          <br />
          Check out the important dates for the EcoWay Token.
        </p>
        <Link to="/about">
          <button className="pillBtn olive">
            Learn more<img src={arrow} alt="" />
          </button>
        </Link>
      </div>
      <div className="panels"></div>
    </div>
    <div className="currentStage">
      <div className="csTxt">
        <h2>
          Check our
          <br /> wallet
        </h2>
        <div className="preSale">
          <h4>Chia and forks in one place</h4>
          <p className="preSaleDesc">
            {/* Minimum purchase: <b>500 ECY</b> (4500 USD) <br /> */}
            <span>
              Try it for <b>free (beta)</b>
            </span>
            <br />
            <br />
            {/* <span>
              Deadline: <b>until {data.widgetDisplayDate}</b>
            </span> */}
          </p>
			<a href="https://wallet.ecoway.org" target="_blank" rel="noopener noreferrer">
			<button className="pillBtn olive">
				Enter Wallet<img src={arrow} alt="" />
			</button>
			</a>
        </div>
      </div>
      <div className="cables"></div>
    </div>
  </>
);
