import "./style.scss";
import arrow from "img/icons/misc/arrow-white.svg";
import { useEffect, useState } from "react";

export const Staking = () => {
  const [totalCoins, setTotalCoins] = useState(0);

  async function getCoinsCount() {
    try {
      let response = await fetch(
        "https://stake.ecoway.org/api/stakings/total-coins-count",
        {
          method: "GET",
        }
      );

      let res = await response.json();

      if (res.count) {
        setTotalCoins(res.count);
      } else {
        console.log(res);
      }
      return;
    } catch (error) {
      console.log(error);
    }
  }

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  useEffect(() => {
    if (totalCoins === 0) getCoinsCount();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="staking" id="staking">
      <div className="stakingFlex">
        <div className="stakingTxt">
          <h2>Staking</h2>
          <p className="stakingDesc">
            Stake tokens to access chia farming profits without building your
            own infrastructure.
          </p>
          <div className="stakeTxtBox">
            <b>{numberWithSpaces(Math.round(totalCoins))}</b>
            <span>Overall staking tokens</span>
          </div>
          {/* <div className="stakeTxtBox">
            <b>3</b>
            <span>Already staking users</span>
          </div> */}
          <p className="stakeButtonTitle">Stake your ECY Tokens</p>
          <a
            className="icoWrapper"
            href="https://stake.ecoway.org"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <button className="pillBtn whiteGreen">
              <span>Stake NOW</span>
              <img src={arrow} alt="" />
            </button>
          </a>
        </div>
        <div className="stakingBigPhotoWrapper">
          <div className="stakingBigPhoto" />
        </div>
      </div>
    </div>
  );
};
