import founders from "./founders.svg";
import holders from "./holders.svg";
import logo from "./logo.svg";
import maintenance from "./maintenance.svg";
import marketing from "./marketing.svg";
import partners from "./partners.svg";

export const CHARTS = {
  founders,
  holders,
  logo,
  maintenance,
  marketing,
  partners,
};
