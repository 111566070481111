import "./style.scss";
import data from "data/emissionData.json";

export const Overview = () => {
  const {
    totalECY,
    circulatingECY,
    initialCapUSD,
    initialCirculatingECY,
    initialPriceUSD,
  } = data.tokenomicsOverview;

  return (
    <div className="overviewTokenomics" id="overviewTokenomics">
      <h2>
        Over<span>view</span>
      </h2>
      <div className="overviewTiles">
        <div className="overviewTile">
          <span>Token</span>
          <span>Ticker</span>
          <br />
          <b>ECY</b>
        </div>

        <div className="overviewTile">
          <span>Token</span>
          <span>Standard</span>
          <br />
          <b>BEP20</b>
          <b>(BSC)</b>
        </div>

        <div className="overviewTile">
          <span>Total</span>
          <span>Supply</span>
          <br />
          <b>{totalECY}</b>
          <b>ECY</b>
        </div>
        <div className="overviewTile">
          <span>Circulating</span>
          <span>Supply</span>
          <br />
          <b>{circulatingECY}</b>
          <b>ECY</b>
        </div>
      </div>
    </div>
  );
};
