import logo from "img/logos/ecoway/white.svg";
import "./style.scss";
import { isBigScreen, useBreakpoint } from "app/Hooks/useBreakpoint";
import { AllYouNeed } from "app/components/AllYouNeed";
import { Link } from "react-router-dom";
import { Navbar } from "app/components/Navbar";
import menu from "img/icons/misc/menu.svg";
import { useSettingsContext } from "app/context";

export const TopBar = () => {
  const bp = useBreakpoint();
  const { setIsMenuOpen } = useSettingsContext();
  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <div className="topbar">
      <Link onClick={closeMenu} to="/">
        <img className="ecowayLogo" src={logo} alt="logo" />
      </Link>
      <Navbar />
      <a
        className="whiteBtnWrapper"
        target="_blank"
        href="https://stake.ecoway.org"
        rel="nofollow noopener noreferrer"
      >
      </a>
      {isBigScreen(bp) && <AllYouNeed position="top" />}

      <button onClick={openMenu} className="openMenuButton">
        <img src={menu} alt="menu" />
      </button>
    </div>
  );
};
