import arrow from "img/icons/misc/arrow-white.svg";
import "./style.scss";
import { TopBar } from "app/components/TopBar";
import { scrollTo } from "app/utils/scrollTo";

export const Top = () => {
  return (
    <>
      <div className="topAbout">
        <TopBar />
        <a
          className="icoWrapper"
          href="https://ico.ecoway.org/login"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <button className="buyTokenBanner">
            <span>
              <b>Buy token</b> here
            </span>
            <img src={arrow} alt="buy token" />
          </button>
        </a>
        <div className="wrapper">
          <div className="knowUs">
            <h1>
              <b>Earn</b> &
              <br />
              <b>Invest</b>
            </h1>
            <>
              <button
                className="pillBtn olive"
                onClick={() => scrollTo("emission")}
              >
                <b>Emission Progress</b>
              </button>
              <button
                className="pillBtn olive"
                onClick={() => scrollTo("staking")}
              >
                <b>Staking</b>
              </button>
              <button
                className="lastBtn pillBtn olive"
                onClick={() => scrollTo("roiCalc")}
              >
                <b>ROI Calculator</b>
              </button>
            </>
          </div>
        </div>
      </div>
      <div className="btns">
        <button className="pillBtn olive" onClick={() => scrollTo("emission")}>
          <b>Emission Progress</b>
        </button>
        <button className="pillBtn olive" onClick={() => scrollTo("staking")}>
          <b>Staking</b>
        </button>
        <button className="pillBtn olive" onClick={() => scrollTo("roiCalc")}>
          <b>ROI Calculator</b>
        </button>
      </div>
    </>
  );
};
