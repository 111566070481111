import { Top } from "./components/Top";
import { Emission } from "./components/Emission";
import { Staking } from "./components/Staking";
import { Calc } from "./components/Calc";
import { Footer } from "app/components/Footer";
import {Helmet} from "react-helmet";

export const StakeAndDistribution = () => (
  <div className="stakeAndDistribution page">
    <Helmet>
      <title>Staking – EcoWay Token</title>
      <meta name="description" content="Earn rewards by staking tokens. Gain access to chia farming profits without building your own infrastructure. Maximize your holdings. Buy EcoWay token." />
    </Helmet>
    <Top />
    <Emission />
    <Staking />
    <Calc />
    <Footer />
  </div>
);
