import { useEffect, useState } from "react";

const names = ["none", "XS", "SM", "vHD", "XGA", "HD", "UXGA", "FHD"];

export const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState("");
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    if (0 < windowSize.width && windowSize.width < 330) {
      setBreakPoint(names[0]);
    }
    if (330 < windowSize.width && windowSize.width < 365) {
      setBreakPoint(names[1]);
    }
    if (365 < windowSize.width && windowSize.width < 720) {
      setBreakPoint(names[2]);
    }
    if (720 < windowSize.width && windowSize.width < 1020) {
      setBreakPoint(names[3]);
    }
    if (1020 < windowSize.width && windowSize.width < 1280) {
      setBreakPoint(names[4]);
    }

    if (1280 < windowSize.width && windowSize.width < 1590) {
      setBreakPoint(names[5]);
    }

    if (1590 < windowSize.width && windowSize.width < 1910) {
      setBreakPoint(names[6]);
    }

    if (windowSize.width >= 1910) {
      setBreakPoint(names[7]);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize.width]);
  return breakpoint;
};

export const isBigScreen = (bp) =>
  Boolean(
    bp === "vHD" || bp === "XGA" || bp === "HD" || bp === "UXGA" || bp === "FHD"
  );
