import { Capsule } from "app/components/Capsule";
import Carousel, { autoplayPlugin, slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import NasDaq from "./img/2560px-NASDAQ_Logo.svg.png"
import AsiaOne from "./img/AsiaOne_logo.svg.png"
import CoinQuora from "./img/CoinQuora-logo.png"
import Entrepreneur from "./img/Entrepreneur_logo.png"
import MarketWatch from "./img/marketwatch-logo-vector-download.png"
import Bloomberg from "./img/New_Bloomberg_Logo.svg.png"
import NamZalezy from "./img/screenshot-www.youtube.com-2022.07.18-20_15_43.jpg"
import StreetInsider from "./img/Street-Inside.png"
import YahooFinance from "./img/yahoo_finance_en-US_h_p_financev2.png"
import APNews from "./img/ap-news-logo.webp"
import DigitalYournal from "./img/Digital-Journal-NEW.jpg"
import "./style.scss";

const InTheMedia = () => (
  <div className="in-the-media">
    <h3 className="title">
      In <b>The Media</b>
    </h3>
    <div className="media-list">
      <Carousel plugins={[
        'arrows',
        'infinite',
        {
          resolve: autoplayPlugin,
          options: {
            interval: 2000,
          }
        },
        {
          resolve: slidesToShowPlugin,
          options: {
           numberOfSlides: 3
          }
        },
      ]}
      breakpoints={{
        640: {
          plugins: [
            'arrows',
            'infinite',
            {
              resolve: autoplayPlugin,
              options: {
                interval: 2000,
              }
            },
            {
              resolve: slidesToShowPlugin,
              options: {
               numberOfSlides: 1
              }
            },
         ]
        },
        900: {
          plugins: [
            'arrows',
            'infinite',
            {
              resolve: autoplayPlugin,
              options: {
                interval: 2000,
              }
            },
            {
              resolve: slidesToShowPlugin,
              options: {
               numberOfSlides: 2
              }
            },
         ]
        }
      }}
      animationSpeed={1000}>
        {/* <a href="" target="_blank" rel="noopener noreferrer"><img src={NasDaq} alt="NasDaq"/></a>
        <a href="" target="_blank" rel="noopener noreferrer"><img src={AsiaOne} alt="AsiaOne"/></a>
        <a href="" target="_blank" rel="noopener noreferrer"><img src={CoinQuora} alt="CoinQuora"/></a>
        <a href="" target="_blank" rel="noopener noreferrer"><img src={Entrepreneur} alt="Entrepreneur"/></a> */}
        <a href="https://www.marketwatch.com/press-release/ecoway-makes-green-crypto-farming-accessible-to-everyone-with-staking-platform-introduction-2022-07-19" target="_blank" rel="noopener noreferrer"><img src={MarketWatch} alt="MarketWatch"/></a>
        {/* <a href="" target="_blank" rel="noopener noreferrer"><img src={Bloomberg} alt="Bloomberg"/></a> */}
        <a href="https://www.youtube.com/watch?v=1oj6quwlAi8" target="_blank" rel="noopener noreferrer"><img src={NamZalezy} alt="NamZalezy"/></a>
        <a href="https://www.streetinsider.com/The+Financial+Capital/EcoWay+Makes+Green+Crypto+Farming+Accessible+to+Everyone+With+Staking+Platform+Introduction/20338353.html" target="_blank" rel="noopener noreferrer"><img src={StreetInsider} alt="StreetInsider"/></a>
        <a href="https://finance.yahoo.com/news/ecoway-makes-green-crypto-farming-113000035.html" target="_blank" rel="noopener noreferrer"><img src={YahooFinance} alt="YahooFinance"/></a>
        <a href="https://apnews.com/press-release/marketersmedia/climate-and-environment-190f6cbc1351b836f0f8a2fbc627fb3b" target="_blank" rel="noopener noreferrer"><img src={APNews} alt="APNews"/></a>
        <a href="https://www.digitaljournal.com/pr/ecoway-makes-green-crypto-farming-accessible-to-everyone-with-staking-platform-introduction-2" target="_blank" rel="noopener noreferrer"><img src={DigitalYournal} alt="DigitalYournal"/></a>
      </Carousel>
    </div>
  </div>
);

export default InTheMedia