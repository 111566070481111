import { NavLink } from "react-router-dom";
import ROUTES from "data/routes.json";
import links from "data/socialLinks.json";
import { SOCIAL_MOBILE_ICONS as icons } from "img/icons/social/mobile";
import close from "img/icons/misc/close.svg";
import "./style.scss";
import { useEffect } from "react";
import { useLocation } from "react-router";

export const MobileMenu = ({ setOpen }) => {
  const closeMenu = () => setOpen(false);
  const location = useLocation();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="mobileMenu">
      <button onClick={closeMenu} className="closeMenu">
        <img src={close} alt="close" />
      </button>
      <nav>
        <ul>
          {ROUTES.map(({ name, route }) => (
            <li key={route}>
              <NavLink
                to={route}
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={closeMenu}
              >
                {name}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <div className="menuBtnsWrapper">
        <a
          className="menuWhiteBtnWrapper"
          href="https://stake.ecoway.org"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <button className="whiteBtn">Stake ECY Token</button>
        </a>
        <a
          className="icoWrapper"
          href="https://ico.ecoway.org/login"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
        </a>
      </div>
      <ul className="mobileMenuDocuments">
        <li>
          <a target="_blank" href="/assets/Whitepaper.pdf">
            Download Whitepaper
          </a>
        </li>
        <li>
          <a target="_blank" href="/assets/Litepaper.pdf">
            Download Litepaper
          </a>
        </li>
      </ul>
      <ul className="mobileMenuSocialLinks">
        {links.map(({ url, name }) => (
          <li key={name}>
            <a href={url} rel="nofollow noopener noreferrer">
              <img src={icons[name]} alt={name} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
