import arrow from "img/icons/misc/arrow-white.svg";
import { TopBar } from "app/components/TopBar";
import { scrollTo } from "app/utils/scrollTo";

export const Top = () => {
  return (
    <>
      <div className="topAbout">
        <TopBar />
        <a
          className="icoWrapper"
          href="https://ico.ecoway.org/login"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
        </a>
        <div className="wrapper">
          <div className="knowUs">
            <h1>
              Cryptocurrency
              <br />
              <b>ecosystem</b>
            </h1>
            <>
              <button
                className="pillBtn olive"
                onClick={() => scrollTo("overviewTokenomics")}
              >
                <b>Overview</b>
              </button>
              <button
                className="pillBtn olive"
                onClick={() => scrollTo("allocationTokenomics")}
              >
                <b>Token Allocation</b>
              </button>
              <button
                className="lastBtn pillBtn olive"
                onClick={() => scrollTo("networkTokenomics")}
              >
                <b>Our Network</b>
              </button>
            </>
          </div>
        </div>
      </div>
      <div className="btns">
        <button
          className="pillBtn olive"
          onClick={() => scrollTo("overviewTokenomics")}
        >
          <b>Overview</b>
        </button>
        <button
          className="pillBtn olive"
          onClick={() => scrollTo("allocationTokenomics")}
        >
          <b>Token Allocation</b>
        </button>
        <button
          className="pillBtn olive"
          onClick={() => scrollTo("networkTokenomics")}
        >
          <b>Our Network</b>
        </button>
      </div>
    </>
  );
};
