import { GoUp } from "app/components/GoUp";
import "./style.scss";
import data from "data/emissionData.json";
export const Network = () => {
  const {
    totalNetworkSpace,
    ourFarmNetworkSpace,
    onlineRigs,
    totalPlots,
    estimatedTimeToWinBlock,
    minedChia,
  } = data.ourNetwork;

  return (
    <div className="networkTokenomics" id="networkTokenomics">
      <h2>
        Our
        <br />
        <span>Network</span>
      </h2>
      <div className="networkFlex">
        <div className="networkTile">
          <span>Total Network Space</span>
          <b>{totalNetworkSpace}</b>
          <div className="networkTileSeparator" />
          <span>Our Farm Network Space</span>
          <b>{ourFarmNetworkSpace}</b>
        </div>
        <div className="networkTile">
          <span>Online Rigs</span>
          <b>{onlineRigs}</b>
          <div className="networkTileSeparator" />
          <span>Total plots</span>
          <b>{totalPlots}</b>
        </div>
        <div className="networkTile">
          <span>Estimated time to win block</span>
          <b>{estimatedTimeToWinBlock}</b>
          <div className="networkTileSeparator" />
          <span>Mined chia</span>
          <b>{minedChia}</b>
        </div>
      </div>
      <GoUp />
    </div>
  );
};
