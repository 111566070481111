import facebook from "./facebook.svg";
import twitter from "./twitter.svg";
import linkedin from "./linkedin.svg";
import telegram from "./telegram.svg";
import youtube from "./youtube.svg";
import instagram from "./instagram.svg";
import medium from "./medium.svg";
import btctalk from "./btctalk.svg";

export const SOCIAL_MOBILE_ICONS = {
  facebook,
  twitter,
  linkedin,
  telegram,
  youtube,
  instagram,
  medium,
  btctalk,
};
