import React, { useState, useEffect } from 'react';
import { Capsule } from "app/components/Capsule";
import { SMALL_EMISSION_ICONS as icons } from "img/icons/misc/small";
import { Item } from "../components/Item";
import { Progress } from "../components/Progress";
import "../style.scss";

export const MobileEmissionCapsule = () => {
  const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date().toLocaleDateString());
    }, 1000); // Aktualizacja daty co sekundę

    return () => clearInterval(intervalId); // Wyczyść interwał przy odmontowywaniu
  }, []);

  return (
    <Capsule>
      <Item pic={icons.sun} title={"ECY"} subtitle="token name" />
      <Item pic={icons.clock} title={currentDate} subtitle="last update" />
      <Item
        pic={icons.star}
        title="184 710" // Stały tekst zamiast obliczanej wartości
        subtitle="circulating supply"
      />
      <Progress />
    </Capsule>
  );
};
