import "./style.scss";
import { CHARTS } from "img/icons/charts";
import arrow from "img/icons/misc/arrow-white.svg";
import copy from "img/icons/misc/copy.svg";
import copied from "img/icons/misc/copied.svg";
import { useState } from "react";
const { holders, founders, maintenance, marketing, partners } = CHARTS;
const items = [
  {
    percentage: "75%",
    desc: "Token Holders",
    color: "#71B54D",
    icon: holders,
  },
  {
    percentage: "10%",
    desc: "Founders",
    color: "#B8EBD2",
    icon: founders,
  },
  {
    percentage: "7%",
    desc: "Maintenance costs",
    color: "#6EC4A1",
    icon: maintenance,
  },
  {
    percentage: "5%",
    desc: "Marketing",
    color: "#46A69B",
    icon: marketing,
  },
  {
    percentage: "3%",
    desc: "Partners",
    color: "#44BBCF",
    icon: partners,
  },
];

const walletAddress = "0x3a14785035CF80aD95a969Bfc750584a3C16A6E6";

export const Allocation = () => {
  const [wasCopied, setWasCopied] = useState(false);

  return (
    <div className="allocationTokenomics" id="allocationTokenomics">
      <h2>
        Token
        <br />
        <span>Allocation</span>
      </h2>
      <div className="allocationOuterWrapper">
        <div className="allocationInnerWrapper">
          <img className="allocationChartLogo" src={CHARTS.logo} alt="chart" />
          <div className="allocationSmallCharts">
            {items.map(({ percentage, desc, color, icon }) => (
              <div className="allocationSmallChartTile" key={desc}>
                <img src={icon} alt="" />
                <div className="allocationSmallChartTile__desc">
                  <b style={{ color }}>{percentage}</b>
                  <span>{desc}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="allocationAddress">
          <div className="allocationAddressWrapper">
            <div>
              <b>ECY Smart</b>
              <b>Contract Address:</b>
            </div>
            <span>
              {walletAddress}
              <button
                onClick={() => {
                  navigator.clipboard.writeText(walletAddress);
                  setWasCopied(true);
                }}
              >
                <img
                  src={wasCopied ? copied : copy}
                  alt="copy wallet address"
                />
              </button>
            </span>
          </div>
          <a
            className="icoWrapper"
            href="https://ico.ecoway.org/login"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
          </a>
        </div>
      </div>
    </div>
  );
};
