import links from "data/socialLinks.json";
import { SOCIAL_WHITE_ICONS as icons } from "img/icons/social/white";
import "./style.scss";
import { AllYouNeed } from "app/components/AllYouNeed";
import { useBreakpoint, isBigScreen } from "app/Hooks/useBreakpoint";
import { TopBar } from "app/components/TopBar";

export const Top = () => {
  const bp = useBreakpoint();

  return (
    <>
      <div className="top">
        <TopBar />
		<div className="wrapper">
		<div className="signUp">
			<h1>
			Log In to
			<br />
			<b>Stake ECY Tokens</b>
			</h1>
			<p>
			and enjoy the benefits of ECY token staking on our platform.
			</p>

            <a
              className="icoWrapper"
              href="https://stake.ecoway.org/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <button className="pillBtn colorful buyToken">Stake ECY Token</button>
            </a>
            {!isBigScreen(bp) && <AllYouNeed position="bottom" />}
          </div>
          <div className="socials">
            {links.map(({ url, name }) => (
              <a key={name} href={url} rel="nofollow noopener noreferrer">
                <img src={icons[name]} alt={name} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
