import "./style.scss";

import bigPlaceholder from "img/photos/vid.webp";
import smallPlaceholder from "img/photos/mobile/vid.webp";
import play from "img/icons/misc/play.svg";
import close from "img/icons/misc/close.svg";
import { MobileEmissionCapsule } from "../EmissionCapsule/MobileEmissionCapsule";
import { DesktopEmissionCapsule } from "../EmissionCapsule/DesktopEmissionCapsule";
import { useEffect, useState } from "react";
import { useBreakpoint, isBigScreen } from "app/Hooks/useBreakpoint";

export const Video = () => {
  const [isVideo, setIsVideo] = useState(false);
  const bp = useBreakpoint();

  const isBig = isBigScreen(bp);

  useEffect(() => {
    setIsVideo(false);
  }, [isBig]);

  return (
    <>
      <div className="video mobile">
        <div className="fill">
          <div className="smallCapsuleWrapper">
            <MobileEmissionCapsule />
          </div>
        </div>
        <div className="container">
          <img className="bg" src={smallPlaceholder} alt="" />
          <div className="content small">
            {!isVideo && (
              <>
                {" "}
                <h3>
                  It's your
                  <br />
                  <b>EcoWay</b> to
                  <br />
                  green
                  <br />
                  crypto
                </h3>
                <img
                  onClick={() => setIsVideo(true)}
                  className="play"
                  src={play}
                  alt="play"
                />
              </>
            )}

            {isVideo && (
              <iframe
                width="100%"
                height="27%"
                src="https://www.youtube.com/embed/zMhXiaRcqyA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </div>
      </div>
      <div className="video desktop">
        <img
          className="bigBg"
          src={bigPlaceholder}
          style={
            isVideo
              ? { borderBottomRightRadius: "0" }
              : { borderBottomRightRadius: "900px" }
          }
          alt=""
        />
        <div className="content big">
          <div
            className="bigCapsuleWrapper"
            style={isVideo ? { opacity: "0" } : { opacity: "1" }}
          >
            <DesktopEmissionCapsule />
          </div>
          {!isVideo && (
            <div className="bigTxt">
              <>
                <h3>
                  It's your
                  <br />
                  <b>EcoWay</b> to
                  <br />
                  green
                  <br />
                  crypto
                </h3>
                <img
                  onClick={() => {
                    setIsVideo(true);
                  }}
                  className="play"
                  src={play}
                  alt="play"
                />
              </>
            </div>
          )}
          {isVideo && (
            <>
              <iframe
                className="vidPlayer"
                src="https://www.youtube.com/embed/zMhXiaRcqyA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <button
                onClick={() => {
                  setIsVideo(false);
                }}
                className="closeVid"
              >
                <img src={close} alt="close video" />
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};
