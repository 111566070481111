import { NavLink } from "react-router-dom";
import ROUTES from "data/routes.json";
import "./style.scss";

export const Navbar = () => (
  <nav className="navBarTop">
    <ul>
      {ROUTES.map(({ name, route }) => (
        <li key={route}>
          <NavLink
            to={route}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            {name}
          </NavLink>
        </li>
      ))}
    </ul>
  </nav>
);
