import { Footer } from "app/components/Footer";
import { Overview } from "./components/Overview";
import { Allocation } from "./components/Allocation";
import { Top } from "./components/Top";
import { Network } from "./components/Network";
import {Helmet} from "react-helmet";

export const Tokenomics = () => (
  <div className="page tokenomics">
    <Helmet>
      <title>Tokenomics – EcoWay</title>
      <meta name="description" content="See the real value of EcoWay. Take a look at token allocation and understand how much EcoWay might be worth in the future. Buy EcoWay token." />
    </Helmet>
    <Top />
    <Overview />
    <Allocation />
    <Network />
    <Footer />
  </div>
);
