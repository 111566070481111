import "./style.scss";
import data from "data/emissionData.json";
import { formatNumber } from "app/utils/bigNumbers";

const { emissionProgress, softCap, hardCap } = data;

const currentCap = emissionProgress < softCap ? softCap : hardCap;
const progressPercentage =
  Math.round((emissionProgress / currentCap) * 100) / 100;

export const Progress = () => (
  <div className="progress">
    <span>
      Overall staking tokens: <br />
      <b>{184427} ECY</b>
    </span>
    <div className="bar">
      <div
        className="completion"
        style={{ width: `${progressPercentage * 100}%` }}
      ></div>
    </div>
  </div>
);
