import React, { useState } from "react";

const contextDefaultValues = {
  isMenuOpen: false,
  setIsMenuOpen: () => {},
};

export const SettingsContext = React.createContext(contextDefaultValues);

export const useSettingsContext = () => {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error("settingsContext was used outside of its Provider");
  }
  return context;
};

const SettingsContextProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <SettingsContext.Provider
      value={{
        isMenuOpen,
        setIsMenuOpen,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
