import "./style.scss";
export const Item = ({ pic, title, subtitle }) => (
  <div className="item">
    <img src={pic} alt="icon" />
    <div className="itemTxt">
      <b>{title}</b>
      <span>{subtitle}</span>
    </div>
  </div>
);
