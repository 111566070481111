import { Top } from "./components/Top";
import { Footer } from "app/components/Footer";
import { About } from "./components/About";
import {Helmet} from "react-helmet";

export const AboutUs = () => (
  <div className="page">
    <Helmet>
      <title>About us – EcoWay</title>
      <meta name="description" content="Learn more about our values and stay up to date about what's happening in the EcoWay. See our roadmap and meet the team behind the project. Buy EcoWay token." />
    </Helmet>
    <Top />
    <About />
    <Footer />
  </div>
);
